import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import { useToggle } from '@react-hookz/web';
import { FC, useState } from 'react';

import AccessOrdersDataTableAnchor from './AccessOrdersDataTableAnchor';
import AccessOrdersDataTableFiltersModal from './AccessOrdersDataTableFiltersModal';
import AccessOrdersDataTableToolbar from './AccessOrdersDataTableToolbar';
import AssemblieStatusChip from './AccessOrdersStatusChip';
import Error from '../Error';
import Loading from '../Loading';
import NoData from '../NoData';
import DataTableBodyRow from '../data-tables/DataTableBodyRow';
import DataTableFooter from '../data-tables/DataTableFooter';
import DataTableHead from '../data-tables/DataTableHead';
import AccessOrderStatusesEnum from '../../enums/access-orders/access-order-statuses.enum';
import useFindAccessOrders from '../../hooks/access-orders/find-access-orders.hook';
import DataTableColumnInterface from '../../interfaces/data-tables/data-table-column.interfaces';
import { useDataTable } from '../../providers/data-tables/DataTableProvider';
import GetAccessOrderPaymentStatusLabelService from '../../services/access-orders/get-access-order-payment-status-label.service';

const { CONFIRMED, INCOMPLETE, PROCESSING } = AccessOrderStatusesEnum;

const getAccessOrderPaymentStatusLabelService =
  new GetAccessOrderPaymentStatusLabelService();

const COLUMNS: DataTableColumnInterface[] = [
  { key: 'status', label: '', disabled: true, sx: { width: '60px', px: 0 } },
  { key: 'commonCompanyName', label: 'Entreprise', sx: { width: '180px' } },
  {
    key: 'accreditationQuantity',
    label: 'Accréditation',
    disabled: true,
    sx: { width: '180px' },
  },
  {
    key: 'paymentStatus',
    label: 'Paiement',
    sx: { width: 'auto' },
  },
];

const AccessOrdersDataTable: FC = () => {
  const { dataTableOptions } = useDataTable();

  const [accessOrderStatuses, setAccessOrderStatuses] = useState<
    AccessOrderStatusesEnum[]
  >([INCOMPLETE, PROCESSING, CONFIRMED]);

  const [filtersModalOpened, toggleFiltersModal] = useToggle(false);

  const { data, status } = useFindAccessOrders(
    { ...dataTableOptions, accessOrderStatuses },
    {
      keepPreviousData: true,
    },
  );

  if (status === 'loading') return <Loading />;
  if (status !== 'success') return <Error />;
  if (!data) return <NoData />;

  return (
    <Paper>
      <AccessOrdersDataTableToolbar toggleFiltersModal={toggleFiltersModal} />

      <Box sx={{ overflow: 'scroll' }}>
        <Table sx={{ tableLayout: 'fixed' }}>
          <DataTableHead columns={COLUMNS} />

          <TableBody>
            {data.nodes.map((accessOrder) => {
              const { id, includedAccreditationQuantity } = accessOrder;

              return (
                <DataTableBodyRow key={id}>
                  <TableCell>
                    <AssemblieStatusChip accessOrder={accessOrder} />
                  </TableCell>
                  <TableCell>
                    <AccessOrdersDataTableAnchor accessOrder={accessOrder} />
                  </TableCell>
                  <TableCell>{includedAccreditationQuantity}</TableCell>
                  <TableCell>
                    {getAccessOrderPaymentStatusLabelService.get(
                      accessOrder.paymentStatus,
                    )}
                  </TableCell>
                </DataTableBodyRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>

      <DataTableFooter pageInfo={data.pageInfo} />

      <AccessOrdersDataTableFiltersModal
        opened={filtersModalOpened}
        onClose={(): void => toggleFiltersModal(false)}
        accessOrderStatuses={accessOrderStatuses}
        onAccessOrderStatusesChange={(accessOrderStatuses): void =>
          setAccessOrderStatuses(accessOrderStatuses)
        }
      />
    </Paper>
  );
};

export default AccessOrdersDataTable;
