import Button from '@mui/material/Button';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { FC, MouseEventHandler, useCallback, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import ExhibitorDashboardCard from './ExhibitorDashboardCard';
import AccessOrderDto from '../../dto/access-orders/out/access-order.dto';
import EditionDto from '../../dto/editions/out/edition.dto';
import AccessOrderStatusesEnum from '../../enums/access-orders/access-order-statuses.enum';
import { PATHS } from '../../utils/paths';

const { CANCELED, CONFIRMED, INCOMPLETE, PROCESSING } = AccessOrderStatusesEnum;

interface Props {
  accessOrder: AccessOrderDto;
  edition: EditionDto;
}

const ExhibitorDashboardAccessOrderCard: FC<Props> = ({
  accessOrder,
  edition,
}) => {
  const navigate = useNavigate();

  const accessOrderStatus = useMemo(
    () => accessOrder?.status ?? INCOMPLETE,
    [accessOrder],
  );

  const chipColor = useMemo(() => {
    if (!edition.accessOrdersOpened) return 'default';

    switch (accessOrderStatus) {
      case CANCELED: {
        return 'default';
      }
      case INCOMPLETE: {
        return 'error';
      }
      case PROCESSING: {
        return 'warning';
      }
      case CONFIRMED: {
        return 'success';
      }
    }
  }, [accessOrderStatus, edition]);

  const chipLabel = useMemo(() => {
    if (!edition.accessOrdersOpened) return 'Disponible prochainement';

    switch (accessOrderStatus) {
      default:
      case INCOMPLETE: {
        return 'Incomplet';
      }
      case PROCESSING: {
        return 'En traitement';
      }
      case CONFIRMED: {
        return 'Réservé';
      }
      case CANCELED: {
        return 'Annulé';
      }
    }
  }, [accessOrderStatus, edition]);

  const buttonText = useMemo(
    () => (accessOrderStatus === INCOMPLETE ? 'Compléter' : 'Consulter'),
    [accessOrderStatus],
  );

  const buttonVariant = useMemo(
    () => (accessOrderStatus === INCOMPLETE ? 'contained' : 'text'),
    [accessOrderStatus],
  );

  const handleNavigation: MouseEventHandler<HTMLButtonElement> = useCallback(
    (event) => {
      event.preventDefault();

      const path =
        accessOrderStatus === INCOMPLETE
          ? PATHS.exhibitors.accessOrders.edit.path
          : PATHS.exhibitors.accessOrder.path;

      navigate(path);
    },
    [accessOrderStatus, navigate],
  );

  return (
    <ExhibitorDashboardCard>
      <CardContent>
        <Chip color={chipColor as 'default'} label={chipLabel} sx={{ mb: 3 }} />

        <Typography gutterBottom variant="h3">
          Commandes d’accès au Salon
        </Typography>

        <Typography variant="body2" color="text.secondary">
          Les accréditations sont au nom de l’entreprise uniquement. Sachez
          que si vous le désirez, il vous est possible d’acheter des
          accréditations supplémentaires ou encore des billets de prévente.
        </Typography>

        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          À remplir au plus tard le <strong>vendredi 17 janvier 2025</strong>.
        </Typography>
      </CardContent>

      {edition.accessOrdersOpened && (
        <CardActions>
          <Button variant={buttonVariant} onClick={handleNavigation}>
            {buttonText}
          </Button>
        </CardActions>
      )}
    </ExhibitorDashboardCard>
  );
};

export default ExhibitorDashboardAccessOrderCard;
