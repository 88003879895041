import { useMutation, UseMutationResult } from 'react-query';

import SaveProfileEmailDto from '../../dto/profiles/in/save-profile-email.dto';
import SaveProfileEmailService from '../../services/profiles/save-profile-email.service';

const useSaveProfileEmail = (): UseMutationResult<
  void,
  Error,
  { id: string; dto: SaveProfileEmailDto }
> => {
  return useMutation((variables) =>
    new SaveProfileEmailService().save(variables.id, variables.dto),
  );
};

export default useSaveProfileEmail;
