import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useToggle } from '@react-hookz/web';
import { parseISO } from 'date-fns';
import { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { FiEdit2 } from 'react-icons/fi';

import AdminAssemblyDeliveryPreferenceModal from './AdminAssemblyDeliveryPreferenceModal';
import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import ReadonlyFieldLabel from '../forms/ReadonlyFieldLabel';
import Select from '../forms/Select';
import TimePicker from '../forms/TimePicker';
import AssemblyDto from '../../dto/assemblies/out/assembly.dto';
import ProfileDto from '../../dto/profiles/out/profile.dto';
import OptionInterface from '../../interfaces/option.interface';
import AdminAssemblyFieldValues from '../../interfaces/assemblies/admin-assembly-field-values.interface';
import { formatDateLong } from '../../utils/date';
import { validateRequired } from '../../utils/validations';
import {
  ASSEMBLY_DELIVERY_DATES,
  ASSEMBLY_DELIVERY_DATES_PRE_KITCHEN,
  ASSEMBLY_DELIVERY_DATE_KITCHEN,
} from '../../values';

const DELIVERY_DATE_OPTIONS: OptionInterface[] = [
  { label: 'Sélectionner', value: '' },
  ...ASSEMBLY_DELIVERY_DATES_PRE_KITCHEN,
  ASSEMBLY_DELIVERY_DATE_KITCHEN,
  ...ASSEMBLY_DELIVERY_DATES,
];

interface Props {
  assembly: AssemblyDto;
  profile: ProfileDto;
}

const AdminAssemblyDeliveryPreferenceForm: FC<Props> = ({
  assembly,
  profile,
}) => {
  const { control } = useFormContext<AdminAssemblyFieldValues>();
  const [modalOpened, toggleModal] = useToggle(false);

  const renderDeliveryDatePreference = useMemo(() => {
    if (
      !assembly.deliveryDatePreference ||
      !assembly.deliveryDatePeriodPreference
    ) {
      return null;
    }

    const date = formatDateLong(parseISO(assembly.deliveryDatePreference));

    return (
      <Grid item xs={12}>
        <ReadonlyFieldLabel>Journée et période désirées</ReadonlyFieldLabel>

        <Grid container direction="row" alignItems="center">
          <Grid item xs={4}>
            <Typography>
              {date} {assembly.deliveryDatePeriodPreference}
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <Button
              variant="text"
              onClick={(): void => toggleModal(true)}
              startIcon={<FiEdit2 />}
            >
              Modifier
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }, [assembly, toggleModal]);

  return (
    <>
      <FormStack>
        <FormGrid>
          {renderDeliveryDatePreference}

          <Grid item xs={12} md={4}>
            <Select
              control={control}
              name="deliveryDate"
              label="Confirmation de la journée"
              options={DELIVERY_DATE_OPTIONS}
              rules={validateRequired()}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <TimePicker
              control={control}
              name="deliveryTime"
              label="Confirmation de l'heure"
              rules={validateRequired()}
            />
          </Grid>
        </FormGrid>
      </FormStack>

      <AdminAssemblyDeliveryPreferenceModal
        assembly={assembly}
        opened={modalOpened}
        profile={profile}
        onClose={(): void => toggleModal(false)}
      />
    </>
  );
};

export default AdminAssemblyDeliveryPreferenceForm;
