import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import { useToggle } from '@react-hookz/web';
import { FC, useCallback, useMemo } from 'react';
import { FiArchive, FiEdit2, FiMail } from 'react-icons/fi';

import AdminProfileEmailModal from './AdminProfileEmailModal';
import ModalConfirmation from '../ModalConfirmation';
import DataTableBodyRowAction from '../data-tables/DataTableBodyRowAction';
import ProfilesDto from '../../dto/profiles/out/profiles.dto';
import ProfileStatusesEnum from '../../enums/profiles/profile-statuses.enum';
import useArchiveProfile from '../../hooks/profiles/archive-profile.hook';
import useSendProfileReminder from '../../hooks/profiles/send-profile-reminder.hook';
import useUnarchiveProfile from '../../hooks/profiles/unarchive-profile.hook';
import { useDataTable } from '../../providers/data-tables/DataTableProvider';
import { queryClient } from '../../utils/query-client';
import { toast } from '../../utils/toast';

const { CANCELED, INCOMPLETE } = ProfileStatusesEnum;

const LIST_ITEM_ICON_STYLE = { color: 'grey.800' };

interface Props {
  profile: ProfilesDto;
  refetchProfiles: () => void;
}

const ProfilesDataTableBodyRowAction: FC<Props> = ({
  profile,
  refetchProfiles,
}) => {
  const [modalCancellationOpened, toggleModalCancellation] = useToggle(false);
  const [modalReminderOpened, toggleModalReminder] = useToggle(false);
  const [modalSaveEmailOpened, toggleModalSaveEmail] = useToggle(false);
  const { dataTableOptions } = useDataTable();
  const { mutateAsync: archiveProfile } = useArchiveProfile();
  const { mutateAsync: unarchiveProfile } = useUnarchiveProfile();
  const { mutateAsync: sendProfileReminder } = useSendProfileReminder();

  const invalidateProfilesQuery = useCallback(async () => {
    await queryClient.invalidateQueries(['profiles', dataTableOptions]);
  }, [dataTableOptions]);

  const handleCancel = useCallback(async () => {
    try {
      const action =
        profile.status !== CANCELED ? archiveProfile : unarchiveProfile;

      await action({ id: profile.id });

      await invalidateProfilesQuery();

      toggleModalCancellation(false);

      toast.success(`${profile.legalCompanyName} annulé avec succès`);
    } catch (error) {
      toast.error();
    }
  }, [
    archiveProfile,
    invalidateProfilesQuery,
    profile,
    toggleModalCancellation,
    unarchiveProfile,
  ]);

  const handleSendProfileReminder = useCallback(async () => {
    try {
      await sendProfileReminder({ id: profile.id });

      await invalidateProfilesQuery();

      toggleModalReminder(false);

      toast.success(`${profile.legalCompanyName} notifié avec succès`);
    } catch (error) {
      toast.error();
    }
  }, [
    invalidateProfilesQuery,
    profile,
    sendProfileReminder,
    toggleModalReminder,
  ]);

  const actionTextsCancellation = useMemo(
    () =>
      profile.status !== CANCELED
        ? { title: 'Annulation', button: 'Annuler' }
        : { title: 'Réactivation', button: 'Réactiver' },
    [profile],
  );

  return (
    <>
      <DataTableBodyRowAction>
        {profile.status === INCOMPLETE && (
          <MenuItem onClick={(): void => toggleModalReminder(true)}>
            <ListItemIcon sx={LIST_ITEM_ICON_STYLE}>
              <FiMail />
            </ListItemIcon>

            <ListItemText>Notifier</ListItemText>
          </MenuItem>
        )}

        <MenuItem onClick={(): void => toggleModalSaveEmail(true)}>
          <ListItemIcon sx={LIST_ITEM_ICON_STYLE}>
            <FiEdit2 />
          </ListItemIcon>

          <ListItemText>Modifier le courriel</ListItemText>
        </MenuItem>

        <MenuItem onClick={(): void => toggleModalCancellation(true)}>
          <ListItemIcon sx={LIST_ITEM_ICON_STYLE}>
            <FiArchive />
          </ListItemIcon>

          <ListItemText>{actionTextsCancellation.button}</ListItemText>
        </MenuItem>
      </DataTableBodyRowAction>

      <AdminProfileEmailModal
        opened={modalSaveEmailOpened}
        profile={profile}
        onClose={(): void => toggleModalSaveEmail(false)}
        refetchProfiles={refetchProfiles}
      />

      <ModalConfirmation
        opened={modalReminderOpened}
        onClose={(): void => toggleModalReminder(false)}
        onSubmit={handleSendProfileReminder}
        title={`Notification de ${profile.legalCompanyName}`}
      />

      <ModalConfirmation
        opened={modalCancellationOpened}
        onClose={(): void => toggleModalCancellation(false)}
        onSubmit={handleCancel}
        title={`${actionTextsCancellation.title} de ${profile.legalCompanyName}`}
      />
    </>
  );
};

export default ProfilesDataTableBodyRowAction;
