import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useToggle } from '@react-hookz/web';
import { FC, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { FiEdit2 } from 'react-icons/fi';

import AdminIncludedAccreditationQuantityModal from './AdminIncludedAccreditationQuantityModal';
import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import ReadonlyFieldLabel from '../forms/ReadonlyFieldLabel';
import TextField from '../forms/TextField';
import AccessOrderDto from '../../dto/access-orders/out/access-order.dto';
import ProfileDto from '../../dto/profiles/out/profile.dto';
import AdminAccessOrderFieldValues from '../../interfaces/access-orders/admin-access-order-field-values.interface';
import { validateRequired } from '../../utils/validations';
import BookingDto from '../../dto/bookings/out/booking.dto';

interface Props {
  accessOrder: AccessOrderDto;
  booking?: BookingDto;
  profile: ProfileDto;
}

const AdminAccessOrderAccreditationsForm: FC<Props> = ({
  accessOrder,
  booking,
  profile,
}) => {
  const { control } = useFormContext<AdminAccessOrderFieldValues>();
  const [modalOpened, toggleModal] = useToggle(false);

  const renderIncludedAccreditationQuantity = useMemo(() => {
    if (!booking)
      return (
        <Typography>Confirmer la réservation pour éditer ce champ</Typography>
      );

    return (
      <Grid container direction="row" alignItems="center">
        <Grid item xs={1}>
          <Typography>{accessOrder.includedAccreditationQuantity}</Typography>
        </Grid>

        <Grid item xs={4}>
          <Button
            variant="text"
            onClick={(): void => toggleModal(true)}
            startIcon={<FiEdit2 />}
          >
            Modifier
          </Button>
        </Grid>
      </Grid>
    );
  }, [booking, accessOrder, toggleModal]);

  return (
    <>
      <FormStack>
        <FormGrid>
          <Grid item xs={12}>
            <TextField
              control={control}
              label="Nom de l'entreprise à afficher sur l'accréditation"
              name="companyName"
              rules={validateRequired()}
            />
          </Grid>

          <Grid item xs={12}>
            <ReadonlyFieldLabel sx={{ mt: 0 }}>
              Nombre d&apos;accréditations incluses
            </ReadonlyFieldLabel>

            {renderIncludedAccreditationQuantity}
          </Grid>
        </FormGrid>
      </FormStack>

      <AdminIncludedAccreditationQuantityModal
        accessOrder={accessOrder}
        opened={modalOpened}
        profile={profile}
        onClose={(): void => toggleModal(false)}
      />
    </>
  );
};

export default AdminAccessOrderAccreditationsForm;
