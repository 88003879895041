import { AxiosError, AxiosInstance } from 'axios';

import { authState } from './auth.state';
import HttpErrorInterface from './interfaces/http-error.interface';

export const setRequestInterceptor = (axiosInstance: AxiosInstance): void => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const { token } = authState;

      if (!token) return config;

      config.headers.setAuthorization(`Bearer ${token}`);

      return config;
    },
    (error) => Promise.reject(error),
  );
};

export const setResponseInterceptor = (axiosInstance: AxiosInstance): void => {
  axiosInstance.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const { config, response } = error as AxiosError<HttpErrorInterface>;

      const retryConfig = { ...config };

      const status = response?.status ?? 500;

      if (
        status !== 401 ||
        /auth/.test(config?.url ?? '') ||
        retryConfig.retried
      ) {
        return Promise.reject(error);
      }

      if (!authState.refreshing) {
        try {
          authState.refresh();
        } catch (error) {
          return Promise.reject(error);
        }
      }

      return new Promise((resolve) => {
        authState.queue.push((token) => {
          resolve(
            axiosInstance({
              ...retryConfig,
              headers: { Authorization: `Bearer ${token}` },
              retried: true,
            }),
          );
        });
      });
    },
  );
};
