import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { plainToClass } from 'class-transformer';
import { FC, useCallback, useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import Modal from '../Modal';
import ButtonLoadingIcon from '../forms/ButtonLoadingIcon';
import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import RadioButtons from '../forms/RadioButtons';
import Select from '../forms/Select';
import SaveAssemblyDeliveryPreferencesDto from '../../dto/assemblies/in/save-assembly-delivery-preferences.dto';
import AssemblyDto from '../../dto/assemblies/out/assembly.dto';
import ProfileDto from '../../dto/profiles/out/profile.dto';
import DatePeriodsEnum from '../../enums/date-periods.enum';
import useFindAssemblyByUserId from '../../hooks/assemblies/find-assembly-by-user-id.hook';
import useSaveAssemblyDeliveryPreferences from '../../hooks/assemblies/save-assembly-delivery-preferences.hook';
import OptionInterface from '../../interfaces/option.interface';
import { toast } from '../../utils/toast';
import { onInvalidSubmit, validateRequired } from '../../utils/validations';
import {
  ASSEMBLY_DELIVERY_DATES,
  ASSEMBLY_DELIVERY_DATES_PRE_KITCHEN,
  ASSEMBLY_DELIVERY_DATE_KITCHEN,
} from '../../values';

const SECTION_CODE_KITCHEN = 'SCU';

interface Props {
  assembly: AssemblyDto;
  opened: boolean;
  profile: ProfileDto;
  onClose: () => void;
}

interface AdminAssemblyDeliveryPreferenceFieldValues {
  deliveryDatePreference?: string;
  deliveryDatePeriodPreference?: DatePeriodsEnum;
}

const AdminAssemblyDeliveryPreferenceModal: FC<Props> = ({
  assembly,
  opened,
  profile,
  onClose,
}) => {
  const { mutateAsync: saveAssemblyDeliveryPreferences } =
    useSaveAssemblyDeliveryPreferences();

  const { refetch } = useFindAssemblyByUserId(profile.user.id);

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<AdminAssemblyDeliveryPreferenceFieldValues>({
    defaultValues: {
      deliveryDatePreference: assembly.deliveryDatePreference,
      deliveryDatePeriodPreference: assembly.deliveryDatePeriodPreference,
    },
  });

  const onSubmit: SubmitHandler<AdminAssemblyDeliveryPreferenceFieldValues> =
    useCallback(
      async (fieldValues) => {
        try {
          const dto = plainToClass(SaveAssemblyDeliveryPreferencesDto, {
            deliveryDatePreference: fieldValues.deliveryDatePreference,
            deliveryDatePeriodPreference:
              fieldValues.deliveryDatePeriodPreference,
          });

          await saveAssemblyDeliveryPreferences({ id: assembly.id, dto });

          await refetch();

          onClose();

          toast.success('Journée et période désirées modifiées avec succès');
        } catch (error) {
          toast.error();
        }
      },
      [assembly, onClose, refetch, saveAssemblyDeliveryPreferences],
    );

  const deliveryDateOptions = useMemo(() => {
    const options: OptionInterface[] = [
      { label: 'Sélectionner', value: '' },
      ...ASSEMBLY_DELIVERY_DATES_PRE_KITCHEN,
    ];

    if (profile.section?.code === SECTION_CODE_KITCHEN) {
      options.push(ASSEMBLY_DELIVERY_DATE_KITCHEN);
    }

    return [...options, ...ASSEMBLY_DELIVERY_DATES];
  }, [profile]);

  return (
    <Modal open={opened} onClose={onClose}>
      <form>
        <FormStack>
          <FormGrid>
            <Grid item xs={12}>
              <Typography variant="overline" component="h2">
                Modifier
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Grid item xs={12} md={6}>
                <Select
                  control={control}
                  name="deliveryDatePreference"
                  label="Journée et période désirées"
                  options={deliveryDateOptions}
                  rules={validateRequired()}
                  fullWidth
                />
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid item xs={12} md={6}>
                <RadioButtons
                  control={control}
                  name="deliveryDatePeriodPreference"
                  options={[
                    { label: 'En matinée', value: DatePeriodsEnum.AM },
                    { label: 'En après-midi', value: DatePeriodsEnum.PM },
                  ]}
                  rules={validateRequired()}
                  row
                />
              </Grid>
            </Grid>

            <Grid item xs={12} textAlign="right">
              <Button sx={{ mr: 4 }} onClick={onClose}>
                Annuler
              </Button>

              <Button
                disabled={isSubmitting}
                variant="contained"
                sx={{ float: 'right' }}
                startIcon={isSubmitting && <ButtonLoadingIcon />}
                onClick={handleSubmit(onSubmit, onInvalidSubmit)}
              >
                Enregistrer
              </Button>
            </Grid>
          </FormGrid>
        </FormStack>
      </form>
    </Modal>
  );
};

export default AdminAssemblyDeliveryPreferenceModal;
