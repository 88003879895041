import DetailedSheetsReportDto from '../../dto/detailed-sheets/out/detailed-sheets-report.dto';
import { apiV1 } from '../../http';

class GetDetailedSheetsReportService {
  async get(editionId: string): Promise<DetailedSheetsReportDto[]> {
    const { data } = await apiV1.get<DetailedSheetsReportDto[]>(
      `/editions/${editionId}/report/detailed-sheets`,
    );

    return data;
  }
}

export default GetDetailedSheetsReportService;
