import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { plainToClass } from 'class-transformer';
import { FC, useCallback } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import Modal from '../Modal';
import ButtonLoadingIcon from '../forms/ButtonLoadingIcon';
import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import TextField from '../forms/TextField';
import SaveProfileEmailDto from '../../dto/profiles/in/save-profile-email.dto';
import ProfilesDto from '../../dto/profiles/out/profiles.dto';
import ErrorTypesEnum from '../../enums/error-types.enum';
import useFindProfile from '../../hooks/profiles/find-profile.hook';
import useSaveProfileEmail from '../../hooks/profiles/save-profile-email.hook';
import { toast } from '../../utils/toast';
import { onInvalidSubmit, validateEmail } from '../../utils/validations';

interface Props {
  profile: ProfilesDto;
  opened: boolean;
  onClose: () => void;
  refetchProfiles: () => void;
}

interface AdminProfileEmailFieldValues {
  email: string;
}

const AdminProfileEmailModal: FC<Props> = ({
  opened,
  profile,
  onClose,
  refetchProfiles,
}) => {
  const { mutateAsync: saveProfileEmail } = useSaveProfileEmail();

  const { refetch: refetchProfile } = useFindProfile(profile.id);

  const {
    control,
    formState: { isSubmitting },
    handleSubmit,
  } = useForm<AdminProfileEmailFieldValues>({
    defaultValues: {
      email: profile.user.email,
    },
  });

  const onSubmit: SubmitHandler<AdminProfileEmailFieldValues> = useCallback(
    async ({ email }) => {
      try {
        const dto = plainToClass(SaveProfileEmailDto, {
          email,
        });

        await saveProfileEmail({ id: profile.id, dto });

        await Promise.all([refetchProfiles(), refetchProfile()]);

        onClose();

        toast.success('Courriel modifié avec succès');
      } catch (error) {
        const { message } = error as Error;

        switch (message) {
          case ErrorTypesEnum.DUPLICATE_ENTRY_ERROR: {
            toast.error(`L'utilisateur ${email} existe déjà`);

            break;
          }
          default: {
            toast.error();

            break;
          }
        }
      }
    },
    [profile, onClose, refetchProfiles, refetchProfile, saveProfileEmail],
  );

  return (
    <Modal open={opened} onClose={onClose}>
      <form>
        <FormStack>
          <FormGrid>
            <Grid item xs={12}>
              <Typography variant="overline" component="h2">
                Modifier
              </Typography>
            </Grid>

            <Grid item xs={12} sm={8} mb={2}>
              <TextField
                control={control}
                name="email"
                label="Courriel"
                rules={validateEmail()}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} textAlign="right">
              <Button sx={{ mr: 4 }} onClick={onClose}>
                Annuler
              </Button>

              <Button
                disabled={isSubmitting}
                variant="contained"
                sx={{ float: 'right' }}
                startIcon={isSubmitting && <ButtonLoadingIcon />}
                onClick={handleSubmit(onSubmit, onInvalidSubmit)}
              >
                Enregistrer
              </Button>
            </Grid>
          </FormGrid>
        </FormStack>
      </form>
    </Modal>
  );
};

export default AdminProfileEmailModal;
