import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import * as Sentry from '@sentry/react';
import { plainToClass } from 'class-transformer';
import { FC, useCallback } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useToggle } from '@react-hookz/web';

import ModalConfirmation from '../../../ModalConfirmation';
import AccessOrderAccreditationsForm from '../../../access-orders/AccessOrderAccreditationsForm';
import ButtonLoadingIcon from '../../../forms/ButtonLoadingIcon';
import Section from '../../../layouts/Section';
import SectionHeader from '../../../layouts/SectionHeader';
import SaveUserAccessOrderDto from '../../../../dto/access-orders/in/save-user-access-order.dto';
import AccessOrderDto from '../../../../dto/access-orders/out/access-order.dto';
import ProfileDto from '../../../../dto/profiles/out/profile.dto';
import useSaveUserAccessOrder from '../../../../hooks/access-orders/save-user-access-order.hook';
import AccessOrderFieldValues from '../../../../interfaces/access-orders/access-order-field-values.interface';
import GetProfileCompanyNameService from '../../../../services/profiles/get-profile-compamy-name.service';
import { PATHS } from '../../../../utils/paths';
import { toast } from '../../../../utils/toast';
import { onInvalidSubmit } from '../../../../utils/validations';

const getProfileCompanyNameService = new GetProfileCompanyNameService();

interface OutletContext {
  accessOrder: AccessOrderDto;
  profile: ProfileDto;
}

const EditAccessOrderPage: FC = () => {
  const navigate = useNavigate();

  const [modalOpened, toggleModal] = useToggle(false);

  const { accessOrder, profile } = useOutletContext<OutletContext>();

  const { mutateAsync: saveUserAccessOrder } = useSaveUserAccessOrder();

  const formMethods = useForm<AccessOrderFieldValues>({
    defaultValues: {
      companyName:
        accessOrder.companyName ?? getProfileCompanyNameService.get(profile),
    },
  });

  const {
    formState: { isSubmitting },
    getValues,
    handleSubmit,
  } = formMethods;

  const handleSave = useCallback(async () => {
    try {
      const fieldValues = getValues();

      const dto = plainToClass(SaveUserAccessOrderDto, {
        companyName: fieldValues.companyName?.trim(),
      });

      await saveUserAccessOrder(dto);

      toast.success('Enregistré avec succès');

      navigate(PATHS.exhibitors.path);
    } catch (error) {
      toast.error();

      Sentry.captureException(error);
    } finally {
      toggleModal(false);
    }
  }, [getValues, navigate, saveUserAccessOrder, toggleModal]);

  const onSubmit: SubmitHandler<AccessOrderFieldValues> =
    useCallback(async () => {
      toggleModal(true);
    }, [toggleModal]);

  return (
    <FormProvider {...formMethods}>
      <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
        <SectionHeader>
          <Typography variant="h1" gutterBottom>
            Commandes d&apos;accès au Salon
          </Typography>
        </SectionHeader>

        <Section>
          <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
            Accréditations
          </Typography>

          <AccessOrderAccreditationsForm accessOrder={accessOrder} />
        </Section>

        <Section>
          <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
            Billets de prévente
          </Typography>

          <Grid item xs={12}>
            <Typography component="p" sx={{ mb: 3 }}>
              Invitez vos partenaires, clients et employés à venir vous voir à Expo habitat Québec! 
              En achetant des billets de prévente, vous économisez 5$ sur le prix régulier!
              Tarif unitaire préférentiel au coût de 11 $ (plus frais de billetterie).
            </Typography>

            <Typography component="p" sx={{ mb: 3 }}>
                Pour acheter des billets de prévente, veuillez cliquer sur le lien ci-dessous.
            </Typography>

            <Link
              href="https://lepointdevente.com/billets/evq250220001"
              target="_blank"
            >
              Billets de prévente
            </Link>
          </Grid>
        </Section>

        <Button
          disabled={isSubmitting}
          type="submit"
          variant="contained"
          sx={{ float: 'right' }}
          startIcon={isSubmitting && <ButtonLoadingIcon />}
        >
          Enregistrer et envoyer
        </Button>

        <ModalConfirmation
          opened={modalOpened}
          title="Voulez-vous vraiment envoyer la demande ?"
          text="Votre demande sera officiellement envoyée pour traitement. Une fois soumis, il ne vous sera plus possible de modifier le formulaire manuellement. "
          onSubmit={handleSave}
          onClose={(): void => toggleModal(false)}
        />
      </form>
    </FormProvider>
  );
};

export default EditAccessOrderPage;
