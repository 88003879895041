import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form';

import FormGrid from '../forms/FormGrid';
import FormStack from '../forms/FormStack';
import TextField from '../forms/TextField';
import AccessOrderDto from '../../dto/access-orders/out/access-order.dto';
import AccessOrderFieldValues from '../../interfaces/access-orders/access-order-field-values.interface';
import { validateRequired } from '../../utils/validations';

interface Props {
  accessOrder: AccessOrderDto;
}

const AccessOrderAccreditationsForm: FC<Props> = ({ accessOrder }) => {
  const { control } = useFormContext<AccessOrderFieldValues>();

  return (
    <>
      <Typography component="p" sx={{ mb: 3 }}>
        Avec votre espace, vous bénéficiez de{' '}
        <strong>
          {accessOrder.includedAccreditationQuantity} accréditation(s)
        </strong>{' '}
        sans frais pour vos représentants. Ces accréditations ne sont pas
        nominatives et n&apos;indiquent que le nom de l&apos;entreprise.
      </Typography>
      <FormStack>
        <FormGrid>
          <Grid item xs={12}>
            <TextField
              control={control}
              label="Nom de l'entreprise à afficher sur l'accréditation"
              name="companyName"
              rules={validateRequired()}
            />
          </Grid>
        </FormGrid>
      </FormStack>

      <Typography component="p" sx={{ mb: 3 }}>
        Pour acheter des accréditations supplémentaires au coût unitaire de 11 $ (plus frais de billetterie), veuillez cliquer sur le lien ci-dessous.
      </Typography>

      <Typography component="p">
        <Link
              href="https://lepointdevente.com/billets/evq250220001"
              target="_blank"
            >
              Accréditations supplémentaires
        </Link>
      </Typography>
    </>
  );
};

export default AccessOrderAccreditationsForm;
