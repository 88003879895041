import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { useToggle } from '@react-hookz/web';
import * as Sentry from '@sentry/react';
import { plainToClass } from 'class-transformer';
import { FC, useCallback } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useOutletContext } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import ModalConfirmation from '../../../ModalConfirmation';
import AdminAssemblyDeliveryPreferenceForm from '../../../assemblies/AdminAssemblyDeliveryPreferenceForm';
import AdminAssemblyDisassemblyForm from '../../../assemblies/AdminAssemblyDisassemblyForm';
import AdminAssemblyNeedsPreferenceForm from '../../../assemblies/AdminAssemblyNeedsPreferenceForm';
import AdminAssemblyOnSiteRepresentativeForm from '../../../assemblies/AdminAssemblyOnSiteRepresentativeForm';
import ButtonLoadingIcon from '../../../forms/ButtonLoadingIcon';
import Section from '../../../layouts/Section';
import ProfileTabs from '../../../profiles/ProfileTabs';
import ProfileTitle from '../../../profiles/ProfileTitle';
import AccessOrderDto from '../../../../dto/access-orders/out/access-order.dto';
import SaveAssemblyDto from '../../../../dto/assemblies/in/save-assembly.dto';
import AssemblyDto from '../../../../dto/assemblies/out/assembly.dto';
import BookingDto from '../../../../dto/bookings/out/booking.dto';
import DetailedSheetDto from '../../../../dto/detailed-sheets/out/detailed-sheet.dto';
import ProfileDto from '../../../../dto/profiles/out/profile.dto';
import AssemblyStatusesEnum from '../../../../enums/assemblies/assembly-statuses.enum';
import DoorTypesEnum from '../../../../enums/assemblies/door-types.enum';
import HandlingEquipmentsEnum from '../../../../enums/assemblies/handling-equipments.enum';
import useConfirmAssembly from '../../../../hooks/assemblies/confirm-assembly.hook';
import useFindAssemblyByUserId from '../../../../hooks/assemblies/find-assembly-by-user-id.hook';
import useSaveAssembly from '../../../../hooks/assemblies/save-assembly.hook';
import AdminAssemblyFieldValues from '../../../../interfaces/assemblies/admin-assembly-field-values.interface';
import { toast } from '../../../../utils/toast';
import { onInvalidSubmit } from '../../../../utils/validations';
import {
  formatTime,
  getDateFromDateAndTimeString,
} from '../../../../utils/date';

interface OutletContext {
  accessOrder: AccessOrderDto;
  assembly: AssemblyDto;
  booking?: BookingDto;
  detailedSheet: DetailedSheetDto;
  profile: ProfileDto;
}

const AdminEditAssemblyPage: FC = () => {
  const [modalOpened, toggleModal] = useToggle(false);
  const [isConfirming, setIsConfirming] = useToggle(false);

  const { accessOrder, assembly, booking, detailedSheet, profile } =
    useOutletContext<OutletContext>();

  const userId = profile.user.id;

  const queryClient = useQueryClient();
  const { refetch } = useFindAssemblyByUserId(profile.user.id);
  const { mutateAsync: confirmAssembly } = useConfirmAssembly();
  const { mutateAsync: saveAssembly } = useSaveAssembly();

  const deliveryDate = assembly.deliveryDate ?? assembly.deliveryDatePreference;

  const formMethods = useForm<AdminAssemblyFieldValues>({
    defaultValues: {
      firstName: assembly.firstName ?? '',
      lastName: assembly.lastName ?? '',
      email: assembly.email ?? '',
      phoneNumber: assembly.phoneNumber ?? '',
      handlingEquipment:
        assembly.handlingEquipment ?? HandlingEquipmentsEnum.FORKLIFT,
      doorType: assembly.doorType ?? DoorTypesEnum.GARAGE_GROUND_LEVEL,
      needsNotes: assembly.needsNotes ?? '',
      disassemblyDate: assembly.disassemblyDate ?? '',
      deliveryDate: deliveryDate ?? '',
      deliveryTime:
        assembly.deliveryDate && assembly.deliveryTime
          ? getDateFromDateAndTimeString(
              assembly.deliveryDate,
              assembly.deliveryTime,
            )
          : undefined,
      doorNumber: assembly.doorNumber ? `${assembly.doorNumber}` : '',
    },
  });

  const {
    formState: { isDirty, isSubmitting },
    handleSubmit,
    reset,
  } = formMethods;

  const onSubmit: SubmitHandler<AdminAssemblyFieldValues> = useCallback(
    async (fieldValues) => {
      try {
        const dto = plainToClass(SaveAssemblyDto, {
          firstName: fieldValues.firstName?.trim(),
          lastName: fieldValues.lastName?.trim(),
          email: fieldValues.email?.trim(),
          phoneNumber: fieldValues?.phoneNumber?.trim(),
          handlingEquipment: fieldValues.handlingEquipment,
          doorType: fieldValues.doorType,
          doorNumber: fieldValues.doorNumber,
          needsNotes: fieldValues.needsNotes?.trim(),
          deliveryDate: fieldValues.deliveryDate,
          deliveryTime: fieldValues.deliveryTime
            ? formatTime(fieldValues.deliveryTime)
            : undefined,
          disassemblyDate: fieldValues.disassemblyDate,
        });

        await saveAssembly({ id: assembly.id, dto });
        await refetch();

        toast.success('Enregistré avec succès');

        reset({}, { keepValues: true });
      } catch (error) {
        toast.error();

        Sentry.captureException(error);
      }
    },
    [assembly, refetch, reset, saveAssembly],
  );

  const handleConfirm = useCallback(async () => {
    try {
      setIsConfirming(true);

      await confirmAssembly({ id: assembly.id });

      queryClient.setQueryData<AssemblyDto | undefined>(
        ['users/assembly', userId],
        (assembly) => {
          if (assembly) {
            return {
              ...assembly,
              status: AssemblyStatusesEnum.CONFIRMED,
            };
          }
        },
      );

      toast.success('Confirmé et notifié avec succès');
    } catch (error) {
      toast.error();

      Sentry.captureException(error);
    } finally {
      setIsConfirming(false);
      toggleModal(false);
    }
  }, [
    assembly,
    queryClient,
    userId,
    confirmAssembly,
    setIsConfirming,
    toggleModal,
  ]);

  return (
    <>
      <ProfileTitle
        accessOrder={accessOrder}
        assembly={assembly}
        detailedSheet={detailedSheet}
        profile={profile}
      />

      <ProfileTabs booking={booking} profile={profile} value="assembly" />

      <FormProvider {...formMethods}>
        <form onSubmit={handleSubmit(onSubmit, onInvalidSubmit)}>
          <Section>
            <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
              Représentant sur le site
            </Typography>

            <AdminAssemblyOnSiteRepresentativeForm />
          </Section>

          <Section>
            <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
              Horaire de montage
            </Typography>

            <AdminAssemblyDeliveryPreferenceForm
              assembly={assembly}
              profile={profile}
            />
          </Section>

          <Section>
            <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
              Service de manutention
            </Typography>

            <AdminAssemblyNeedsPreferenceForm />
          </Section>

          <Section>
            <Typography variant="overline" component="h2" sx={{ mb: 2 }}>
              Démontage
            </Typography>

            <AdminAssemblyDisassemblyForm />
          </Section>

          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Tooltip
              title={
                isDirty
                  ? 'Les modifications au formulaire doivent être sauvegardées avant de pouvoir confirmer et notifier'
                  : ''
              }
              arrow
            >
              <span>
                <Button
                  disabled={isDirty || isConfirming}
                  onClick={(): void => toggleModal(true)}
                  startIcon={isConfirming && <ButtonLoadingIcon />}
                  variant="text"
                >
                  Confirmer et notifier
                </Button>
              </span>
            </Tooltip>

            <Button
              disabled={isSubmitting}
              type="submit"
              variant="contained"
              sx={{ float: 'right', ml: 6 }}
              startIcon={isSubmitting && <ButtonLoadingIcon />}
            >
              Enregistrer
            </Button>
          </Box>

          <ModalConfirmation
            opened={modalOpened}
            title="Notifier l'exposant et approuver"
            onSubmit={handleConfirm}
            onClose={(): void => toggleModal(false)}
          />
        </form>
      </FormProvider>
    </>
  );
};

export default AdminEditAssemblyPage;
