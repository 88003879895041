import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { FC } from 'react';

import EditionTabs from '../../../editions/EditionTabs';
import Error from '../../../Error';
import FormGrid from '../../../forms/FormGrid';
import FormStack from '../../../forms/FormStack';
import Section from '../../../layouts/Section';
import SectionHeader from '../../../layouts/SectionHeader';
import Loading from '../../../Loading';
import NoData from '../../../NoData';
import ReportAccessOrdersFile from '../../../reports/ReportAccessOrdersFile';
import ReportAssembliesFile from '../../../reports/ReportAssembliesFile';
import ReportDetailedSheetsFile from '../../../reports/ReportDetailedSheetsFile';
import ReportFile from '../../../reports/ReportFile';
import ProfileStatusesEnum from '../../../../enums/profiles/profile-statuses.enum';
import useFindEditions from '../../../../hooks/editions/find-editions.hook';

const ReportsPage: FC = () => {
  const { data: editions, status } = useFindEditions();

  if (status === 'loading') return <Loading />;
  if (status !== 'success') return <Error />;
  if (!editions) return <NoData />;

  return (
    <>
      <SectionHeader>
        <Typography variant="h2">Édition</Typography>
      </SectionHeader>

      <EditionTabs value="reports" />

      {editions.map((edition) => (
        <Section key={edition.id}>
          <FormStack>
            <FormGrid>
              <Grid xs={12} item>
                <Typography variant="overline" component="h2">
                  Rapports {edition.name}
                </Typography>
              </Grid>

              <ReportFile edition={edition} label="Global" />

              <ReportFile
                edition={edition}
                label={`Exposants officiels ${edition.name}`}
                profileStatus={ProfileStatusesEnum.BOOKED}
              />

              <ReportFile
                edition={edition}
                label="Statuts « En traitement »"
                profileStatus={ProfileStatusesEnum.PROCESSING}
              />

              <ReportFile
                edition={edition}
                label="Statuts « Incomplet »"
                profileStatus={ProfileStatusesEnum.INCOMPLETE}
              />

              <ReportFile
                edition={edition}
                label="Statuts « Annulé »"
                profileStatus={ProfileStatusesEnum.CANCELED}
              />

              <ReportDetailedSheetsFile edition={edition} />

              <ReportAssembliesFile edition={edition} />

              <ReportAccessOrdersFile edition={edition} />
            </FormGrid>
          </FormStack>
        </Section>
      ))}
    </>
  );
};

export default ReportsPage;
