import { utils, writeFile } from 'xlsx';

import DetailedSheetsReportDto from '../../dto/detailed-sheets/out/detailed-sheets-report.dto';
import EditionDto from '../../dto/editions/out/edition.dto';
import { formatDate } from '../../utils/date';

class DownloadDetailedSheetsReportService {
  download(edition: EditionDto, data: DetailedSheetsReportDto[]): void {
    const worksheet = utils.json_to_sheet(data);

    const workbook = utils.book_new();

    utils.book_append_sheet(workbook, worksheet, 'Rapport');

    writeFile(
      workbook,
      `Expo habitat ${edition.name} (Fiches exposant) - ${formatDate(
        new Date().toString(),
      )}.xlsx`,
    );
  }
}

export default DownloadDetailedSheetsReportService;
